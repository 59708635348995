/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// Import functions, variables, and mixins needed by other Bootstrap files
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import 'bootstrap/scss/variables-dark';
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/maps";

// Import Bootstrap Reboot
@import "bootstrap/scss/root"; // Contains :root CSS variables used by other Bootstrap files
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/containers"; // Add .container and .container-fluid classes
@import "bootstrap/scss/grid"; // Add the grid system

@import "bootstrap/scss/utilities"; // Configures the utility classes that should be generated
@import "bootstrap/scss/utilities/api"; // Generates the actual utility classes

@import "_variables";
@import "reset";

$palette: mat.define-palette(mat.$indigo-palette);

html, body {
  margin: 0;
  height: 100%;
}


body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.labeled-box {
  display: inline-block;
  min-width: 100px;
  position: relative;
  font-size: 15px;
  line-height: 15px;
  padding: 16px;
  border: 1px solid #505050;
  border-radius: 5px;
  margin-right: 14px;
  margin-bottom: 14px;
  text-align: center;
}

.labeled-box .label {
  position: absolute;
  font-size: 9px;
  line-height: 9px;
  top: 0;
  left: 18;
  transform: translate(-5px, -50%);
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  color: #505050;
  background-color: white;
}

.labeled-box mat-basic-chip {
  font-size: 15px;
  line-height: 15px;
  font-weight: normal;
  background-color: #E3E3E3;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
  margin: 0 auto;
}

.labeled-box mat-basic-chip:hover {
  cursor: pointer;
}

.labeled-box mat-basic-chip:active {
  cursor: pointer;
}

.image-grid img {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
}

.labeled-box mat-basic-chip {
  font-size: 15px;
  line-height: 15px;
  font-weight: normal;
  background-color: #E3E3E3;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
  margin: 0 auto;
}


.search:hover {
  background-color: #e9e9e9;
}

.search-results-container {
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 4px 0px 4px;
  z-index: 101;
}

.search-result {
  width: 100%;
  height: 52px;
  box-sizing: border-box;
  padding: 6px;
  border-radius: 3px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.search-result:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.search-result:active {
  background-color: #f1f1f1;
  cursor: pointer;
}

.search-result .title {
  flex-grow: 1;
  text-align: left;
  margin-left: 20px;
}

.search-result .image {
  box-sizing: border-box;
  height: 100%;
  width: 40px;
}

.search-result .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.address {
  white-space: pre;
  text-align: left;
}

.image-add-button {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
}

.issue-card {
  width: 300px !important;
  object-fit: contain;
}

mat-chip:hover {
  cursor: pointer;
}

mat-chip:active {
  cursor: pointer;
}

mat-card-title {
  font-size: 24px;
  font-weight: 500;
  padding: 16px 16px;
}

a.nostyle {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

a.nostyle:hover {
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: mat.get-color-from-palette($palette, 'darker');
  animation: spinner .8s linear infinite;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* 
 * These styles are a hack to workaround https://github.com/h2qutc/angular-material-components/issues/357
 */
.mat-datepicker-content, .time-container {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 4px;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
}

.mat-calendar-body-cell-content {
  color: var(--mat-datepicker-calendar-date-text-color);
  border-color: var(--mat-datepicker-calendar-date-outline-color)
}

.mat-calendar-body-selected {
  background-color: var(--mat-datepicker-calendar-date-selected-state-background-color);
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--mat-datepicker-calendar-date-hover-state-background-color);
}
/* End hack */